import React, { useState, useEffect, useRef } from 'react';
import { 
  Scroll, Crown, Copy, CheckCircle, Menu, 
  Feather, Lock, Users, ChevronDown,
  Scale, Landmark, BookOpen, Volume2, VolumeX
} from 'lucide-react';

const ConstitutionalScroll = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [showQuill, setShowQuill] = useState(false);
  const [isInking, setIsInking] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  
  const audioRef = useRef(null);
  const sectionsRef = useRef({});
  const CONTRACT_ADDRESS = 'hairN27yxk865Frneq42FrJSzwokBvR12HZkrTcp3WL';

  // Initialize audio
  useEffect(() => {
    audioRef.current = new Audio('./hair.mp3');
    audioRef.current.loop = true;
    audioRef.current.volume = volume;
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Handle volume changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const handleEnter = () => {
    setIsOpen(true);
    audioRef.current.play();
  };

  const toggleMute = () => {
    if (audioRef.current) {
      const newMutedState = !isMuted;
      audioRef.current.muted = newMutedState;
      setIsMuted(newMutedState);
    }
  };

  // Sections data
  const sections = [
    { id: 'preamble', title: 'Preamble', icon: BookOpen },
    { id: 'tokenomics', title: 'Articles of Tokenomics', icon: Scale },
    { id: 'governance', title: 'Constitutional Rights', icon: Landmark },
  ];

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowQuill(scrollPosition > 100);
      
      Object.entries(sectionsRef.current).forEach(([key, ref]) => {
        if (ref && ref.offsetTop - window.innerHeight * 0.5 < scrollPosition) {
          setActiveSection(key);
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(CONTRACT_ADDRESS);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const InkText = ({ children, delay = 0 }) => {
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
      const timer = setTimeout(() => setIsVisible(true), delay);
      return () => clearTimeout(timer);
    }, [delay]);
    
    return (
      <span className={`transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
        {children}
      </span>
    );
  };

  return (
    <div className="min-h-screen bg-stone-200 flex flex-col items-center p-4 relative overflow-x-hidden cursor-[url('hair.png'),_auto]"
      style={{
        backgroundImage: `
          radial-gradient(circle at 50% 50%, rgba(214, 211, 209, 0.2) 0%, transparent 100%),
          url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.15' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.05'/%3E%3C/svg%3E")
        `
      }}>
      
      {/* Audio Controls */}
      <div className="fixed top-4 right-4 z-50 flex items-center gap-4 bg-stone-100/90 p-2 rounded-lg shadow-md">
        <button
          onClick={toggleMute}
          className="p-2 hover:bg-stone-200 rounded-full transition-colors"
        >
          {isMuted ? (
            <VolumeX className="w-6 h-6 text-stone-600" />
          ) : (
            <Volume2 className="w-6 h-6 text-stone-600" />
          )}
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.05"
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          className="w-24 h-2 bg-stone-300 rounded-lg appearance-none cursor-pointer"
        />
      </div>
      
      {!isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-stone-200/95 z-40">
          <button
            onClick={handleEnter}
            className="group relative flex items-center gap-3 px-8 py-4 text-xl 
              bg-gradient-to-b from-stone-50 to-stone-200 text-stone-800 
              rounded-lg border-2 border-stone-600 hover:from-stone-100 hover:to-stone-300 
              transition-all duration-500 shadow-lg hover:shadow-xl transform 
              hover:-translate-y-1"
            style={{ fontFamily: "'Cinzel Decorative', serif" }}
          >
            <Crown className="h-6 w-6 group-hover:rotate-12 transition-transform duration-300" />
            <span>Enter...</span>
            <Scroll className="h-6 w-6 group-hover:-rotate-12 transition-transform duration-300" />
          </button>
        </div>
      )}

      <div className={`w-full max-w-4xl transition-all duration-1000 ease-out 
        ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}>
        
        <div className="bg-gradient-to-b from-stone-50 to-stone-100 rounded-lg shadow-2xl 
          overflow-hidden relative">
          
          {/* Aged paper texture overlay */}
          <div className="absolute inset-0 opacity-20 mix-blend-multiply pointer-events-none"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='paper'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.5' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='200' height='200' filter='url(%23paper)' opacity='1'/%3E%3C/svg%3E")`
            }}
          />
          
          {/* Water stains */}
          <div className="absolute inset-0 opacity-10 pointer-events-none">
            <div className="absolute top-1/4 left-1/4 w-32 h-32 rounded-full bg-stone-600 blur-3xl" />
            <div className="absolute bottom-1/3 right-1/3 w-40 h-40 rounded-full bg-stone-600 blur-3xl" />
          </div>

          {/* Header */}
          <div className="relative py-16 px-4 border-b-4 border-stone-300">
            <div className="text-center space-y-6">
              <h1 className="text-4xl md:text-6xl lg:text-7xl text-stone-800 tracking-wider"
                style={{ fontFamily: "'WeThePeople', cursive" }}>
                <InkText>$hair</InkText>
              </h1>
              
              <p className="text-lg md:text-xl text-stone-600 italic"
                style={{ fontFamily: "'Playfair Display', serif" }}>
                <InkText delay={500}>
                  "We The People Hold These Memes To Be Self-Evident"
                </InkText>
              </p>

              {/* Seal Image */}
              <div className="flex justify-center my-8">
                <div className="relative w-64 h-64 rounded-full overflow-hidden
                  shadow-[0_0_15px_rgba(0,0,0,0.2),inset_0_0_15px_rgba(0,0,0,0.2)]
                  border-8 border-stone-300 bg-stone-100 animate-[rotate_60s_linear_infinite]">
                  <img
                    src="/hair.png"
                    alt="Constitutional Seal"
                    className="w-full h-full object-cover opacity-90"
                  />
                  {/* Decorative bezel overlay */}
                  <div className="absolute inset-0 bg-gradient-to-tr from-stone-200/20 to-transparent pointer-events-none" />
                  {/* Parchment texture overlay */}
                  <div className="absolute inset-0 mix-blend-multiply opacity-10 pointer-events-none bg-[url('data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='parchment'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.3' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='200' height='200' filter='url(%23parchment)' opacity='1'/%3E%3C/svg%3E')]" />
                </div>
              </div>

              {/* Contract Address */}
              <div className="max-w-md mx-auto mt-8">
                <button
                  onClick={handleCopy}
                  className="group relative flex items-center justify-center gap-2 w-full px-4 py-2 
                    bg-stone-100 border-2 border-stone-400 rounded-lg hover:bg-stone-50 
                    transition-all duration-300"
                >
                  <span className="text-stone-600 font-mono text-sm">
                    {CONTRACT_ADDRESS.slice(0, 6)}...{CONTRACT_ADDRESS.slice(-4)}
                  </span>
                  {isCopied ? (
                    <CheckCircle className="w-5 h-5 text-green-600" />
                  ) : (
                    <Copy className="w-5 h-5 text-stone-600 group-hover:rotate-12 transition-transform" />
                  )}
                  
                  {/* Tooltip */}
                  <span className="absolute -top-8 text-sm bg-stone-800 text-stone-100 px-2 py-1 
                    rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Copy Contract Address
                  </span>
                </button>
              </div>
            </div>
          </div>

          {/* Preamble Section */}
          <div className="p-8" ref={el => sectionsRef.current.preamble = el}>
            <div className="bg-stone-50/50 rounded-lg p-6 border border-stone-200 shadow-inner">
              <div className="text-center space-y-4">
                <BookOpen className="w-8 h-8 text-stone-600 mx-auto" />
                <h2 className="text-2xl font-semibold text-stone-800"
                  style={{ fontFamily: "'Cinzel Decorative', serif" }}>
                  Preamble
                </h2>
                <p className="text-stone-600 italic leading-relaxed"
                  style={{ fontFamily: "'Playfair Display', serif" }}>
                  We the People of Solana, in Order to form a more perfect Meme,
                  establish Comedy, insure domestic Virality, provide for the common Laughter,
                  promote the general Welfare of our Bags, and secure the Blessings of Liberty
                  to ourselves and our Portfolio, do ordain and establish this Constitution
                  for the $HAIR Token.
                </p>
              </div>
            </div>
          </div>

          {/* Tokenomics Section */}
          <div className="p-8 border-t border-stone-300" ref={el => sectionsRef.current.tokenomics = el}>
            <div className="bg-stone-50/50 rounded-lg p-6 border border-stone-200 shadow-inner">
              <div className="text-center space-y-4">
                <Scale className="w-8 h-8 text-stone-600 mx-auto" />
                <h2 className="text-2xl font-semibold text-stone-800"
                  style={{ fontFamily: "'Cinzel Decorative', serif" }}>
                  Articles of Tokenomics
                </h2>
                <div className="grid md:grid-cols-2 gap-6 mt-4">
                  <div className="p-4 bg-stone-100 rounded-lg border border-stone-300">
                    <h3 className="text-lg font-semibold text-stone-700 mb-2">Article I</h3>
                    <p className="text-stone-600">Total Supply: 1,000,000,000 $HAIR</p>
                  </div>
                  <div className="p-4 bg-stone-100 rounded-lg border border-stone-300">
                    <h3 className="text-lg font-semibold text-stone-700 mb-2">Article II</h3>
                    <p className="text-stone-600">Tax: 0% Buy / 0% Sell</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Constitutional Rights Section */}
          <div className="p-8 border-t border-stone-300" ref={el => sectionsRef.current.governance = el}>
            <div className="bg-stone-50/50 rounded-lg p-6 border border-stone-200 shadow-inner">
              <div className="text-center space-y-4">
                <Landmark className="w-8 h-8 text-stone-600 mx-auto" />
                <h2 className="text-2xl font-semibold text-stone-800"
                  style={{ fontFamily: "'Cinzel Decorative', serif" }}>
                  Constitutional Rights
                </h2>
                <ul className="text-left space-y-4 max-w-2xl mx-auto">
                  <li className="flex items-start gap-3">
                    <div className="w-6 h-6 mt-1 flex-shrink-0">
                      <Crown className="w-6 h-6 text-stone-600" />
                    </div>
                    <p className="text-stone-600">The right to HODL shall not be infringed</p>
                  </li>
                  <li className="flex items-start gap-3">
                    <div className="w-6 h-6 mt-1 flex-shrink-0">
                      <Lock className="w-6 h-6 text-stone-600" />
                    </div>
                    <p className="text-stone-600">LP will be locked for the safety of all citizens</p>
                  </li>
                  <li className="flex items-start gap-3">
                    <div className="w-6 h-6 mt-1 flex-shrink-0">
                      <Users className="w-6 h-6 text-stone-600" />
                    </div>
                    <p className="text-stone-600">Community governance through Democratic vote</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="text-center p-8 border-t border-stone-300 bg-stone-50/50">
            <div className="max-w-md mx-auto">
              <div className="h-px bg-stone-400 my-8" />
              <p className="text-stone-600 italic"
                style={{ fontFamily: "'Playfair Display', serif" }}>
                Signed and sealed in the presence of the blockchain
              </p>
              <div className="mt-4 text-stone-500 text-sm">
                Anno Domini MMXXIV
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ConstitutionalScroll;